<template>
	<div>
		<Header :title="isEdit ? '修改影院信息' : '新增影院信息'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
		<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }" @finish="onSubmit">
			<a-row>
				<a-col :span="12">
					<a-form-item label="影院组织" name="organizationId" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-select :disabled="isSee" v-model:value="modelRef.organizationId" placeholder="请选择影院组织">
							<a-select-option v-for="item in organizationList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="影院城市" name="addressList" :rules="[{required: true, message: '必选项不允许为空'}]">
						<addressSelect :disabled="isSee" v-model:value="modelRef.addressList" @change="onAddress"></addressSelect>
					</a-form-item>

					<a-form-item label="影院名称" name="name" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.name" placeholder="请输入影院名称"></a-input>
					</a-form-item>

					<a-form-item label="影院地址" name="address" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.address" placeholder="请输入影院地址"></a-input>
					</a-form-item>

					<a-form-item label="联系姓名" name="contactPeople" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.contactPeople" placeholder="请输入联系姓名"></a-input>
					</a-form-item>

					<a-form-item label="联系电话" name="contactPhone" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.contactPhone" placeholder="请输入联系电话"></a-input>
					</a-form-item>

          <a-form-item label="售票系统" name="transactionMode">
            <div v-if="modelRef.ticketSystem != 2">凤凰佳影</div>
            <div v-if="modelRef.ticketSystem == 2">辰星</div>
          </a-form-item>

					<a-form-item label="购票模式" name="transactionMode" :rules="[{required: true, message: '必选项不允许为空'}]">
						<a-radio-group :disabled="isSee" v-model:value="modelRef.transactionMode" button-style="solid">
							<a-radio-button :value="1">票务系统出票</a-radio-button>
							<a-radio-button :value="2">云端系统出票</a-radio-button>
						</a-radio-group>
					</a-form-item>

					<a-form-item label="视觉提醒" name="isVisionTips" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-switch :disabled="isSee" v-model:checked="modelRef.isVisionTips" checked-children="开" un-checked-children="关"></a-switch>
					</a-form-item>

					<a-form-item v-if="modelRef.isVisionTips" label="提醒内容" name="visionTipsInfo" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-textarea :disabled="isSee" v-model:value="modelRef.visionTipsInfo" placeholder="请输入提醒内容"></a-textarea>
					</a-form-item>

					<a-form-item label="交通信息" name="trafficInfo">
						<a-textarea :disabled="isSee" v-model:value="modelRef.trafficInfo" placeholder="请输入交通信息"></a-textarea>
					</a-form-item>

					<a-form-item label="影院公告" name="announcement">
						<a-textarea :disabled="isSee" v-model:value="modelRef.announcement" placeholder="请输入影院公告"></a-textarea>
					</a-form-item>

					<a-form-item v-if="id" name="cinemaAreaId" label="选择场区" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-form-item-rest>
							<areaSelect v-model:value="modelRef.cinemaAreaId" :id="id"></areaSelect>
						</a-form-item-rest>
					</a-form-item>

					<a-form-item name="versions" label="数据库版本">
						<a-select :disabled="isSee" v-model:value="modelRef.versions" placeholder="请选择数据库版本" :rules="[{ required: true, message: '必选项不允许为空' }]">
							<a-select-option :value="1">旧版</a-select-option>
							<a-select-option :value="2">新版</a-select-option>
						</a-select>
					</a-form-item>

					<a-form-item label="班次号" name="shiftNo" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.shiftNo" placeholder="请输入班次号"></a-input>
					</a-form-item>

					<a-form-item name="accountId" label="抖音来客账号id">
						<a-input v-model:value="modelRef.accountId" :disabled="isSee" placeholder="请输入抖音来客账号id"></a-input>
					</a-form-item>

          <a-form-item name="customerServiceConfig" label="抖音客服id">
            <a-input v-model:value="modelRef.customerServiceConfig" :disabled="isSee" placeholder="请输入抖音客服id"></a-input>
          </a-form-item>

					<a-form-item label="下单价格类型" name="orderPriceType" v-if="modelRef.transactionMode === 1">
						<a-select :disabled="isSee" v-model:value="modelRef.orderPriceType" placeholder="请选择下单价格类型">
							<a-select-option :value="1">最低票价</a-select-option>
							<a-select-option :value="2">现金价格</a-select-option>
						</a-select>
					</a-form-item>
				</a-col>
				<a-col :span="12">
					<a-form-item label="地址坐标" name="coordinate" :rules="[{required: true, message: '必填项不允许为空'}]">
						<div class="ui-custom__btnInput">
							<a-input :disabled="isSee" v-model:value="modelRef.coordinate" placeholder="请填写地址坐标"></a-input>
							<a-button :disabled="isSee" class="ui-custom__btn" type="primary" @click="onOpenWin">坐标拾取器</a-button>
						</div>
					</a-form-item>

					<a-form-item label="影院国家编码" name="code" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.code" placeholder="请输入国家定义的8位编码"></a-input>
					</a-form-item>

					<a-form-item label="服务时间" name="serviceTime" :rules="[{required: true, message: '必填项不允许为空'}]">
						<a-input :disabled="isSee" v-model:value="modelRef.serviceTime" placeholder="请填写服务时间"></a-input>
					</a-form-item>

					<a-form-item label="优惠描述" name="discountDesc">
						<a-input :disabled="isSee" v-model:value="modelRef.discountDesc" placeholder="请填写优惠描述"></a-input>
					</a-form-item>

				  <a-form-item label="取票后是否允许申请售后" name="isRefund" :labelCol="{span: 11, xxl: 8}" :wrapperCol="{span: 13, xxl: 10 }" :rules="[{required: true, message: '请选择取票后是否允许申请售后'}]">
					<a-radio-group v-model:value="modelRef.isRefund" :disabled="isSee">
					  <a-radio :value="1">允许</a-radio>
					  <a-radio :value="0">不允许</a-radio>
					</a-radio-group>
				  </a-form-item>

					<a-form-item label="延迟销售">
						<a-input-number v-model:value="modelRef.saleDelayTime" :persion="0" :disabled="isSee"></a-input-number> 分
						<p style="padding-top: 6px;">
							<!-- {{ id ? modelRef.saleDelayTime : 30 }} -->
							<p style="color: red;">(放映后延迟销售电影票的时间(分) 负数表示放映前不允许购票的时间)</p>
						</p>
					</a-form-item>

					<a-form-item label="最大票数">
						<p style="padding-top: 6px;">
							{{ id ? modelRef.saleMaxTickets : 6 }}
							<p style="color: red;">每笔交易可订购的最大票数（此值来源：票务系统（参数设置-微信渠道））</p>
						</p>
					</a-form-item>

					<a-form-item label="预售天数">
						30天
					</a-form-item>

					<a-form-item label="客服企微二维码" name="serviceImg" :rules="[{required: true, message: '请上传客服企微二维码'}]">
						<a-upload
							:disabled="isSee"
						        v-model:file-list="fileList"
						        name="file"
						        list-type="picture-card"
						        class="avatar-uploader"
						        :show-upload-list="false"
						        action="/admin/ajaxUpload.do"
						        :before-upload="beforeUpload"
						        @change="handleChange"
						>
						    <img v-if="modelRef.serviceImg" :src="modelRef.serviceImg" alt="avatar" style="width: 100px;height: 100px;"/>
						    <div v-else>
						        <div class="ant-upload-text">上传</div>
						    </div>
						</a-upload>
					</a-form-item>

					<a-form-item label="购票前是否必须加企微客服" :rules="[{ required: true, message: '必选项不允许为空' }]" :labelCol="{span: 10, xxl: 7}" :wrapperCol="{span: 14, xxl: 11 }">
						<a-switch :disabled="isSee" v-model:checked="modelRef.mustAddService" checked-children="是" un-checked-children="否"></a-switch>
					</a-form-item>

					<a-form-item label="3D场次是否提醒购买或自备眼镜" name="dimensionalVisible" :labelCol="{span: 11, xxl: 8}" :wrapperCol="{span: 13, xxl: 10 }">
						<a-radio-group v-model:value="modelRef.dimensionalVisible" :disabled="isSee">
							<a-radio :value="0">不显示</a-radio>
							<a-radio :value="1">显示</a-radio>
						</a-radio-group>
					</a-form-item>

					<a-form-item label="是否只展示当天影片">
						<div v-for="item in filmShowSettingList" :key="item.source">
							{{ item.source === 1 ? '微信小程序' : '抖音小程序' }}：
							<a-radio-group v-model:value="item.isShowNowDay">
								<a-radio :value="1">是</a-radio>
								<a-radio :value="0">否</a-radio>
							</a-radio-group>
						</div>
					</a-form-item>

          <a-form-item label="卖品会员卡抵扣" name="isSnackUseCard" :labelCol="{span: 11, xxl: 8}" :wrapperCol="{span: 13, xxl: 10 }">
            <a-radio-group v-model:value="modelRef.isSnackUseCard" :disabled="isSee">
              <a-radio :value="0">不允许</a-radio>
              <a-radio :value="1">允许</a-radio>
            </a-radio-group>
            <p style="color: red;">允许后，购买卖品可以使用会员卡抵扣，反之则不展示会员卡抵扣入口</p>
          </a-form-item>

          <a-form-item label="全局影城会员卡开关" name="isUseEntityCard" :labelCol="{span: 11, xxl: 8}" :wrapperCol="{span: 13, xxl: 10 }">
            <a-radio-group v-model:value="modelRef.isUseEntityCard" :disabled="isSee">
              <a-radio :value="0">关闭</a-radio>
              <a-radio :value="1">开启</a-radio>
            </a-radio-group>
            <p style="color: red;">关闭后，影城会员卡则无法使用</p>
          </a-form-item>

          <a-form-item label="影院确认提示开关" name="isConfirmCinema" :labelCol="{span: 11, xxl: 8}" :wrapperCol="{span: 13, xxl: 10 }">
            <a-radio-group v-model:value="modelRef.isConfirmCinema" :disabled="isSee">
              <a-radio :value="0">关闭</a-radio>
              <a-radio :value="1">开启</a-radio>
            </a-radio-group>
            <p style="color: red;">开启后，影片、卖品下单前会弹出影院信息确认弹窗</p>
          </a-form-item>
				</a-col>
			</a-row>

			<a-row>
				<a-col :span="24" style="margin-top: 20px;text-align: center;">
					<a-button v-if="!isSee" type="primary" html-type="submit">提交</a-button>
					<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
				</a-col>
			</a-row>
		</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import addressSelect from '@/components/addressSelect/index.vue';
	import areaSelect from '@/components/areaSelect/index.vue';
	import { getOrganizationList, getCinemaDetail, updateCinema, saveCinema } from '@/service/modules/cinema.js';
	export default {
		components: {Header, addressSelect, areaSelect},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			}
		},
		created() {
			this.getOrganizationList();
			if(this.isEdit && this.id) {
				this.getData();
			}
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				modelRef: {
					isVisionTips: false,
					transactionMode: 2,
					cinemaAreaId: [],
					mustAddService: false,
					dimensionalVisible: 1
				},
				addressList: [],
				fileList: [],
				filmShowSettingList: [{
					source: 1,
					isShowNowDay: 0
				}, {
					source: 2,
					isShowNowDay: 0
				}]
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCinemaDetail({
						id: this.id
					});
					this.loading = false;
					if (ret.code === 200) {
						ret.data.isVisionTips = ret.data.isVisionTips ? true : false;
						ret.data.mustAddService = ret.data.mustAddService ? true : false;
						ret.data.coordinate =  ret.data.ycoordinate + ',' + ret.data.xcoordinate;
						ret.data.addressList = [Number(ret.data.provinceId), Number(ret.data.cityId)];
						if (ret.data.areaId) {
							ret.data.addressList.push(Number(ret.data.areaId));
						}
						if (ret.data.cinemaAreaId) {
							ret.data.cinemaAreaId = [ret.data.cinemaAreaId];
						} else {
							ret.data.cinemaAreaId = [];
						}
						if (ret.data.filmShowSettingList && ret.data.filmShowSettingList.length) {
							this.filmShowSettingList = ret.data.filmShowSettingList;
						}
						this.modelRef = ret.data;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				postData.isVisionTips = postData.isVisionTips ? 1 : 0;
				postData.mustAddService = postData.mustAddService ? 1 : 0;
				postData.yCoordinate = postData.coordinate.split(',')[0];
				postData.xCoordinate = postData.coordinate.split(',')[1];
				postData.provinceId = postData.addressList[0];
				postData.cityId = postData.addressList[1];
				if (postData.addressList.length === 3) {
					postData.areaId = postData.addressList[2];
				}
				postData.filmShowSettingList = this.filmShowSettingList;
				delete postData.coordinate;
				delete postData.addressList;
				try {
					let ret;
					this.loading = true;
					if (this.isEdit && this.id) {
						postData.id = this.id;
						if (postData.cinemaAreaId.length) {
							postData.cinemaAreaId = postData.cinemaAreaId[0];
						}
						ret = await updateCinema(postData);
					} else {
						delete postData.cinemaAreaId;
						ret = await saveCinema(postData);
					}
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success('操作成功');
						this.onBack(true);
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				let ret = await getOrganizationList({
					page: 1,
					pageSize: 999999
				});
				this.organizationList = ret.data.list || [];
			},
			onOpenWin() {
				window.open('https://lbs.qq.com/getPoint/');
			},
			onAddress(data) {
				if (data) {
					this.modelRef.province = data[0].fullname;
					this.modelRef.city = data[1].fullname;
					if (data.length === 3) {
						this.modelRef.area = data[2].fullname;
					}
				} else {
					this.modelRef.province = '';
					this.modelRef.city = '';
					this.modelRef.area = '';
				}
			},
			beforeUpload(file) {
			    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
			    if (!isJpgOrPng) {
			        this.$message.error('上传文件格式不正确');
			    }
			    const isLt1M = file.size / 1024 / 1024 < 1;
			    if (!isLt1M) {
			      this.$message.error('上传文件太大了');
			    }
			    return isJpgOrPng && isLt1M;
			},
			handleChange(info) {
			     if (info.file.status === 'done') {
			        //console.log(info.file);
			        this.fileList = info.fileList;
			        const $fileList = [...this.fileList];
			        this.fileList = $fileList.map(file => {
			            if (file.response) {
							this.modelRef.serviceImg = file.response.data.imgUrl;
			                file.url = file.response.data.imgUrl;
			            }
			            return file;
			        });
			    }
			}
		}
	}
</script>

<style scoped>
	.ui-custom__btnInput {
		position: relative;
	}
	.ui-custom__btn {
		position: absolute;
		top: 0;
		right: 0;
		z-index: 9;
	}
</style>
