<template>
	<div>
		<a-form ref="confFormRef" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}"
			:wrapperCol="{span: 16, xxl: 15 }" @finish="onSend">
			<a-spin :spinning="loading">
				<a-form-item label="抖音APPID" name="appId" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.appId" placeholder="请输入抖音APPID"></a-input>
				</a-form-item>

				<a-form-item label="抖音appSecret" name="appSecret" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.appSecret" placeholder="请输入抖音appSecret"></a-input>
				</a-form-item>

				<a-form-item label="抖音paySalt" name="paySalt" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.paySalt" placeholder="请输入抖音paySalt"></a-input>
				</a-form-item>

				<a-form-item label="抖音token" name="token" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.token" placeholder="请输入抖音token"></a-input>
				</a-form-item>

				<a-form-item label="抖音accountId" name="accountId" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.accountId" placeholder="请输入抖音accountId"></a-input>
				</a-form-item>

				<a-form-item label="抖音来客门店id" name="poiId" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.poiId" placeholder="请输入抖音来客门店id"></a-input>
				</a-form-item>

				<a-form-item label="抖音平台公钥" name="publicKey" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.publicKey" placeholder="请输入抖音平台公钥"></a-input>
				</a-form-item>
			</a-spin>
		</a-form>
	</div>
</template>

<script>
	import {
		updateCinemaDataConfig
	} from '@/service/modules/cinema';
	export default {
		props: {
			confList: {
				type: Array,
				default: () => []
			}
		},
		data() {
			return {
				loading: false,
				modelRef: {
					cinemaId: 0,
					appId: '',
					appSecret: '',
					paySalt: '',
					token: '',
					accountId: '',
					poiId: '',
					publicKey: '',
				}
			}
		},
		created() {
			if (this.confList.length) {
				this.confList.forEach(item => {
					this.modelRef.cinemaId = item.cinemaId;
					if (item.dataKey === 'douyin.mini.app-id') {
						this.modelRef.appId = item.dataValue;
					} else if (item.dataKey === 'douyin.mini.app-secret') {
						this.modelRef.appSecret = item.dataValue;
					} else if (item.dataKey === 'douyin.mini.pay-salt') {
						this.modelRef.paySalt = item.dataValue;
					} else if (item.dataKey === 'douyin.pay.token') {
						this.modelRef.token = item.dataValue;
					} else if (item.dataKey === 'douyin.life.account-id') {
						this.modelRef.accountId = item.dataValue;
					} else if (item.dataKey === 'douyin.life.poi-id') {
						this.modelRef.poiId = item.dataValue;
					} else if (item.dataKey === 'douyin.life.public-key') {
						this.modelRef.publicKey = item.dataValue;
					}
				})
			}
		},
		methods: {
			onSubmit() {
				if (this.loading) return;
				this.$refs.confFormRef.validateFields().then((e) => {
					this.onSend();
				});
			},
			async onSend() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				let ret = await updateCinemaDataConfig(postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功');
					this.$emit('close');
				}
			}
		}
	}
</script>

<style>
</style>