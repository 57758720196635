<template>
	<div>
		<a-form ref="confFormRef" :model="modelRef" scrollToFirstError name="form" :labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }" @finish="onSend">
			<a-spin :spinning="loading">
				<a-form-item label="跳转路径" name="path" :rules="[{required: true, message: '必填项不允许为空'}]">
					<a-input v-model:value="modelRef.path" placeholder="请输入跳转路径"></a-input>
				</a-form-item>
			</a-spin>
		</a-form>
	</div>
</template>

<script>
	import { updateMerchantPath } from '@/service/modules/cinema';
	export default {
		props: {
			modelData: {
				type: Object,
				default:() => {}
			},
		},
		data () {
			return {
				loading: false,
				modelRef: {}
			}
		},
		created() {
			this.modelRef = this.$deepClone(this.modelData);
		},
		methods: {
			onSubmit() {
				if (this.loading) return;
				this.$refs.confFormRef.validateFields().then((e) => {
					this.onSend();
				});
			},
			async onSend() {
				let postData = JSON.parse(JSON.stringify(this.modelRef));
				this.loading = true;
				let ret = await updateMerchantPath(postData);
				this.loading = false;
				if (ret.code === 200) {
					this.$message.success('修改成功');
					this.$emit('close');
				}
			}
		}
	}
</script>

<style>
</style>